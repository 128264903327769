
function t(id:string) : string {
    return id; 
}

enum VideoNum {
  Idle, // 0
  P1,
  P2,
  P3,
  P4,
  P5,
  P6,
  P7,
  Home, // 8 Select-Video
  Sustainability, // 9
  GlobalProduction, // 10
  Products, // 11
}

function getVideoFilenameByIndex(index:number) : string {
    const videos = {
        [VideoNum.Idle]: "00.mp4", 
        [VideoNum.P1]: "01_Parts_kitting_and_preparation_Master_IAA-2021_Marketing_06_FullHD.mp4", 
        [VideoNum.P2]: "02_Automated_gapfiller_and_module_assembly_Master_IAA-2021_Marketing_07_FullHD.mp4", 
        [VideoNum.P3]: "03_Lv-Hv_assembly_Master_IAA-2021_Marketing_07_FullHD.mp4", 
        [VideoNum.P4]: "04_Level_assembly_the_wedding_Master_IAA-2021_Marketing_06_FullHD.mp4", 
        [VideoNum.P5]: "05_Leakage_eol_Master_IAA-2021_Marketing_06_FullHD.mp4", 
        [VideoNum.P6]: "06_Automatic_electrical_and_functional_test_eol_Master_IAA-2021_Marketing_06_FullHD.mp4", 
        [VideoNum.P7]: "07_Final_check_webasto_seal_and_packaging_Master_IAA-2021_Marketing _06_FullHD.mp4", 
        [VideoNum.Home]: ".mp4", 
        [VideoNum.Sustainability]: "09_210903_sustainable_v06.mp4", 
        [VideoNum.GlobalProduction]: "10_Global_battery_production.mp4", 
        [VideoNum.Products]: ".mp4", 
    };
    return videos[index]; 
}


export { t, VideoNum, getVideoFilenameByIndex };